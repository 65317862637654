import { Box, ButtonBase, type SxProps } from '@mui/material';
import { flatSx } from '@ocodelib/ui-common';
import clsx from 'clsx';
import { type MouseEventHandler, type ReactNode } from 'react';

interface Props {
  sx?: SxProps;
  className?: string;
  icon: ReactNode;
  count: number | string;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  noBorder?: boolean;
  onClick?: MouseEventHandler;
  textColor?: string;
  iconColor?: string;
  onLoadRef?: (elem: HTMLDivElement | null) => void;
}

export function CountButtonWithIcon(props: Props) {
  const {
    onLoadRef,
    sx,
    className,
    icon,
    count,
    size = 'medium',
    disabled = false,
    noBorder = false,
    textColor = '#666',
    iconColor = '#888',
    onClick,
  } = props;

  return (
    <ButtonBase
      component="div"
      disabled={disabled}
      onClick={onClick}
      ref={onLoadRef}
      className={clsx('CountButtonWithIcon-root', className, {
        x_small: size === 'small',
        x_medium: size === 'medium',
        x_large: size === 'large',
      })}
      sx={flatSx(
        {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: noBorder ? 'none' : '1px solid #ddd',
          borderRadius: 1,

          '& .CountButtonWithIcon-count': {
            fontSize: '0.7rem',
            lineHeight: 1,
            whiteSpace: 'none',
            ml: '2px',
            color: textColor,
          },
          color: iconColor,
          '&.x_small': {
            px: 1,
            py: '4px',
          },
          '&.x_medium': {
            px: 1.5,
            py: '4px',
          },
          '&.x_large': {
            px: 2,
            py: '8px',
          },
          '&.x_small svg': {
            width: 20,
            height: 20,
          },
          '&.x_medium svg': {
            width: 20,
            height: 20,
          },
          '&.x_large svg': {
            width: 24,
            height: 24,
          },
          '&.x_large .CountButtonWithIcon-count': {
            fontSize: '0.9rem',
            ml: '4px',
          },
        },
        sx,
      )}
    >
      {icon}
      <Box className="CountButtonWithIcon-count">{count}</Box>
    </ButtonBase>
  );
}

import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { type BoxProps } from '@mui/material/Box';
import clsx from 'clsx';
import { CountWithSvgIcon } from './CountWithSvgIcon';

interface Props {
  sx?: BoxProps['sx'];
  className?: string;
  count: number | string;
  size?: 'small' | 'medium' | 'large';
}

/**
 * 숫자와 Reply icon
 */
export function CountWithReplyIcon(props: Props) {
  const { className, ...restProps } = props;
  return (
    <CountWithSvgIcon
      icon={<MessageOutlinedIcon />}
      {...restProps}
      className={clsx('CountWithReplyIcon-root', className)}
    />
  );
}
